<template lang="pug">
modal(size='sm' @close='$emit("close")') 
  modal-header(icon='custom-move-from-project') {{ $t('Produkte aus Projekt hinzufügen') }}
  control-input(v-if='items.length' v-model='query' rounded :placeholder='$t("Produkte suchen...")' grey icon='search' class='mb-2')
  div(class='bleed relative')
    overlay(:visible='loading')

    control-select-list(v-model='selected' :options='currentOptions' class='mb-4')
      template(#item='{ data }')
        div(class='flex gap-2')
          div
            project-product-image(:url='data.image' class='-my-1 mr-3 flex-1 mix-blend-multiply')
          div(class='flex flex-col text-left text-sm')
            div(class='font-sm text-text-light') {{ data.brand }}
            div(class='font-bold') {{ data.title }}
          div(class='text-text-light') {{ data.retrailPriceNetString }}

    sticky-container(enabled position='bottom' class='py-2')
      action-bar(class='unbleed')
        btn(medium tertiary data-test-btn='close' @click='$emit("close")') {{ $t('Abort') }}
        btn(primary :disabled='!selected.length' data-test-btn='AddProductsFromProjectToInquiry' @click='submit') 
          div(v-html='$tc(" | <b>1 Produkt </b> hinzufügen | <b>{0} Produkte</b> hinzufügen", selected.length)')
</template>

<script setup lang="ts">
import { type IProjectProductItem } from '../types'
import { useInquiriesStore } from '@/pages/inquiries/store'
import { useProjectsStore } from '@/pages/projects/store'
import { useTranslation } from '@/plugins/translation'
import { type IOption } from '@/types'
import { useFuse } from '@vueuse/integrations/useFuse'
import { defineProps, ref, computed } from 'vue'

const { $t } = useTranslation()
const props = defineProps<{ inquiryId: string; projectId: string }>()
const selected = ref<string[]>([])
const emit = defineEmits(['close'])
const loading = ref(false)
const store = useProjectsStore()
const inqStore = useInquiriesStore()

if (!store.allProjectsAsMap[props.projectId]) {
  store.loadProject(props.projectId)
}

const query = ref('')

const items = computed(
  () => store.allProjectsAsMap[props.projectId]?.items.flatMap((item) => [item, ...item.alternatives]) || [],
)
const { results } = useFuse(
  computed(() => query.value),
  items.value,
  { matchAllWhenSearchEmpty: true, fuseOptions: { keys: ['title'], minMatchCharLength: 1 } },
)

const currentOptions = computed<IOption<IProjectProductItem>[]>(() =>
  results.value.map(
    ({ item }) =>
      ({
        label: item.title,
        value: item.productId,
        data: item,
      }) || [],
  ),
)

const submit = async () => {
  loading.value = true
  await inqStore.addProductsToInquiry(props.projectId, props.inquiryId, props.projectId, selected.value, 0)
  loading.value = false
  emit('close')
}
</script>
